var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.fields.filter((el) => el.group_id == 2)),function(profile){return _c('b-row',{staticClass:"mb-3"},[_c('b-col',{attrs:{"cols":"4"}},[(_vm.form.country == 'ต่างประเทศ')?_c('div',[(
            profile.field_profile_type_id == 24 ||
            profile.field_profile_type_id == 12
          )?_c('p',{staticClass:"text-title-input"},[_vm._v(" "+_vm._s(profile.name)+" "),(profile.required)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]):_vm._e()]):(_vm.form.country == 'ประเทศไทย')?_c('p',{staticClass:"text-title-input"},[_vm._v(" "+_vm._s(profile.name)+" "),(profile.required)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]):_c('div',[(profile.field_profile_type_id == 24)?_c('p',[_vm._v(" "+_vm._s(profile.name)+" "),(profile.required)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]):_vm._e()])]),_c('b-col',{attrs:{"set":(_vm.field = _vm.v.address[_vm.checkIsProfile(profile.field_profile_type_id)]),"id":'field-' + profile.profile_name}},[(profile.field_profile_type_id == 24)?_c('InputSelect',{attrs:{"title":"","placeholder":profile.name,"name":profile.name,"options":profile.field_choices.map((x) => ({
            text: x.name,
            value: x.name,
          })),"isValidate":_vm.field.$error,"v":_vm.field,"className":"mb-2"},on:{"onDataChange":(val) => _vm.handleChangeCountry(val)},scopedSlots:_vm._u([{key:"option-first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("-- ประเทศ --")])]},proxy:true}],null,true),model:{value:(_vm.form[_vm.checkIsProfile(profile.field_profile_type_id)]),callback:function ($$v) {_vm.$set(_vm.form, _vm.checkIsProfile(profile.field_profile_type_id), $$v)},expression:"form[checkIsProfile(profile.field_profile_type_id)]"}}):_vm._e(),(_vm.form.country === 'ประเทศไทย')?_c('div',[(
            profile.field_type_id == 1 && profile.field_profile_type_id !== 19
          )?_c('InputText',{attrs:{"placeholder":profile.name,"textFloat":"","className":"mb-2","isValidate":_vm.field.$error,"v":_vm.field},model:{value:(_vm.form[_vm.checkIsProfile(profile.field_profile_type_id)]),callback:function ($$v) {_vm.$set(_vm.form, _vm.checkIsProfile(profile.field_profile_type_id), $$v)},expression:"form[checkIsProfile(profile.field_profile_type_id)]"}}):_vm._e(),(_vm.checkIsProfile(profile.field_profile_type_id) === 'province')?_c('InputSelect',{attrs:{"title":"","placeholder":"จังหวัด","name":"province","className":"mb-2","options":[
            { text: 'กรุณาเลือกจังหวัด', value: null, disabled: true },
            ..._vm.province.map((x) => ({
              text: x.name,
              value: x.name,
            })),
          ],"isValidate":_vm.field.$error,"v":_vm.field},on:{"onDataChange":(val) => _vm.handleChange(val, 'province')},scopedSlots:_vm._u([{key:"option-first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("-- จังหวัด --")])]},proxy:true}],null,true),model:{value:(_vm.form.province),callback:function ($$v) {_vm.$set(_vm.form, "province", $$v)},expression:"form.province"}}):_vm._e(),(_vm.checkIsProfile(profile.field_profile_type_id) === 'district')?_c('InputSelect',{attrs:{"title":"","placeholder":"เขต","name":"district","className":"mb-2","isValidate":_vm.field.$error,"v":_vm.field,"options":[
            { text: 'กรุณาเลือกเขต', value: null, disabled: true },
            ..._vm.district.map((x) => ({
              text: x.name,
              value: x.name,
            })),
          ],"disabled":!_vm.form.province},on:{"onDataChange":(val) => _vm.handleChange(val, 'district')},scopedSlots:_vm._u([{key:"option-first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("-- เขต --")])]},proxy:true}],null,true),model:{value:(_vm.form.district),callback:function ($$v) {_vm.$set(_vm.form, "district", $$v)},expression:"form.district"}}):_vm._e(),(
            _vm.checkIsProfile(profile.field_profile_type_id) === 'subdistrict'
          )?_c('InputSelect',{attrs:{"title":"","placeholder":"แขวง","name":"subdistrict","className":"mb-2","options":[
            { text: 'กรุณาเลือกแขวง', value: null, disabled: true },
            ..._vm.subdistrict.map((x) => ({
              text: x.name,
              value: x.name,
            })),
          ],"isValidate":_vm.field.$error,"v":_vm.field,"disabled":!_vm.form.district},on:{"onDataChange":(val) => _vm.handleChange(val, 'subdistrict')},scopedSlots:_vm._u([{key:"option-first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("-- แขวง --")])]},proxy:true}],null,true),model:{value:(_vm.form.subdistrict),callback:function ($$v) {_vm.$set(_vm.form, "subdistrict", $$v)},expression:"form.subdistrict"}}):_vm._e(),(_vm.checkIsProfile(profile.field_profile_type_id) === 'zip_code')?_c('InputText',{ref:"postNum",refInFor:true,attrs:{"textFloat":"","placeholder":"รหัสไปรษณีย์","name":"zip_code","className":"mb-2","disabled":true},on:{"onEnter":function($event){return _vm.handleEnter(1)},"onKeypress":function($event){return _vm.isNumber($event)}},model:{value:(_vm.form.zip_code),callback:function ($$v) {_vm.$set(_vm.form, "zip_code", $$v)},expression:"form.zip_code"}}):_vm._e()],1):(_vm.form.country === 'ต่างประเทศ')?_c('div',[(profile.field_profile_type_id == 12)?_c('InputTextArea',{attrs:{"isValidate":_vm.field.$error,"v":_vm.field,"textFloat":"","name":"text","rows":"3"},model:{value:(_vm.form[_vm.checkIsProfile(profile.field_profile_type_id)]),callback:function ($$v) {_vm.$set(_vm.form, _vm.checkIsProfile(profile.field_profile_type_id), $$v)},expression:"form[checkIsProfile(profile.field_profile_type_id)]"}}):_vm._e()],1):_vm._e()],1)],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }